import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { LoaderService } from '@app/services/loader/loader.service';
import { LocalizationService } from '../internationalization/localization.service';
import { UtilsService } from '@app/services/util/util-service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { SignInSignOut, User } from '@app/models/user.model';
import { UserService } from '@app/services/user/user.service';
import { PermitService } from '@app/services/permit/permit-service';
import { Permit } from '@app/models/permit.model';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SiteService } from '@app/services/site/site.service';
import { SiteVisitReason } from '@app/models/sitevisitreason.model';
import { JobService } from '@app/services/job/job.service';
import { PermitStatus } from '@app/shared/enums';

@Component({
    selector: 'app-open-permit',
    templateUrl: './open-permit.component.html',
    styleUrls: ['./open-permit.component.scss'],
    standalone: false
})
export class OpenPermitComponent implements OnInit {
  permitInfoFormGroup!: UntypedFormGroup;
  currentUser: User | undefined;
  signInSignOut: SignInSignOut | undefined;
  currentPermit: Permit | undefined;
  openPermitQnAs: any = [];
  questions: any;
  hasContentError: boolean = false;
  errorMessage: string = '';
  timeRangeError: boolean = false;
  isSubmitPermit: boolean = false;
  locationOfWork: string = "";

  multipleChoiceType: string = "multiplechoice";
  signatureType: string = "signature";
  radioButtonType: string = "radiobutton";
  inputTextType: string = "text";
  siteVisitReasons: SiteVisitReason[] = [];

  private hasErrorTrueRegex = /,"HasContentError":true/g;
  private hasErrorFalseRegex = /,"HasContentError":false/g;

  constructor(private _authService: AuthService,
    private _userService: UserService,
    private _permitService: PermitService,
    public _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _localizationService: LocalizationService,
    private _utilsService: UtilsService,
    private _navigationService: NavigationService,
    private _siteService: SiteService,
    private _jobService: JobService,
    private changeDetectorRef: ChangeDetectorRef) {
    this._navigationService.onChange(false, false);
  }

  async ngOnInit(): Promise<void> {
    this._authService.getUser().then(user => {
      this.currentUser = user;
    });
    this.permitInfoFormGroup = this.initFormGroup();
    await this.getSiteName();
    this.siteVisitReasons = await this._siteService.getVisitReasons();
    await this.checkSignInSignOut();
  }

  private initFormGroup(): UntypedFormGroup {
    const openPermitForm = this._formBuilder.group({
      workOrderNumber: new UntypedFormControl(this.currentPermit?.workOrderNumber, [Validators.required]),
      descriptionOfWork: new UntypedFormControl(this.currentPermit?.descriptionOfWork, [Validators.required]),
      additionalPeopleWork: new UntypedFormControl(this.currentPermit?.additionalPeopleWork),
      estimateDurationFrom: new UntypedFormControl(this.currentPermit?.estimateDurationFrom, [Validators.required]),
      estimateDurationTo: new UntypedFormControl(this.currentPermit?.estimateDurationTo, [Validators.required]),
    }, { validators: this.timeValidator.bind(this) });

    return openPermitForm;
  }

  timeValidator(formGroup: FormGroup) {
    this.timeRangeError = false;
    const estimateDurationFrom = formGroup.get('estimateDurationFrom')?.value;
    const estimateDurationTo = formGroup.get('estimateDurationTo')?.value;

    if (estimateDurationFrom && estimateDurationTo && estimateDurationFrom > estimateDurationTo) {
      this.timeRangeError = true
      return { timeError: true };
    }
    return null;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'workOrderNumber':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_work_order_number_required');
        }
        break;
      case 'descriptionOfWork':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_descriptionOfWork_required');
        }
        break;
      case 'additionalPeopleWork':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_additional_work_details_required');
        }
        break;
      case 'estimateDurationFrom':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_start_time_required');
        }
        break;
      case 'estimateDurationTo':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_end_time_required');
        }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  disableSubmitButton() {
    const workOrderNumberValue = this.permitInfoFormGroup.get('workOrderNumber')?.value;
    const descriptionOfWorkValue = this.permitInfoFormGroup.get('descriptionOfWork')?.value;

    if (workOrderNumberValue && descriptionOfWorkValue) {
      if (workOrderNumberValue.trim() === '' || descriptionOfWorkValue.trim() === '') {
        return true;
      }
    }

    return !this.permitInfoFormGroup.valid;
  }

  async getSiteName() {
    const site = await this._siteService.getSignedInSite();
    if (site) {
      this.locationOfWork = site.name;
    } else {
      alert(this._localizationService.translate('open_permit_no_site_found'));
    }
  }

  async getDescriptionOfWork(): Promise<string> {
    let descriptionOfWork = "";

    if (this.siteVisitReasons && this.siteVisitReasons.length > 0 && this.siteVisitReasons[0].id != "") {
      let notes = "";

      await new Promise<void>((resolve, reject) => {
        this._jobService.getJobsBySelectedReasons(this.siteVisitReasons.map((s) => s.id)).subscribe({
          next: (jobs) => {
            if (jobs) {
              jobs.forEach(job => {
                const siteVisitReason = this.siteVisitReasons.find(x => x.id === job.referenceNumber);

                if (siteVisitReason?.id == job.referenceNumber) {
                  if (job.notes != "") {
                    notes += job.notes + " | ";
                  }
                }
              });

              if (notes != "") {
                descriptionOfWork = notes.substring(0, notes.length - 3);
              }
            }
            resolve();
          },
          error: error => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate("open_permit_error_get_job"));
            }
            reject(error);
          }
        });
      });
    }

    return descriptionOfWork;
  }

  async checkSignInSignOut() {
    
    const workOrderNumber = Array.isArray(this.siteVisitReasons)
    ? this.siteVisitReasons
      .map(r => r.id)
      .filter(id => id)
      .join(', ')
    : undefined;

    const descriptionOfWork = await this.getDescriptionOfWork();

    this._userService.getSignIn().then(signInInfo => {
      if (signInInfo !== undefined && signInInfo.signInSignOutId !== "") {
        this._permitService.getPermitWithFormBySignInSignOutId(signInInfo.signInSignOutId).subscribe({
          next: (permit) => {
            if (permit) {
              this.currentPermit = permit;
              this.checkIfOpenPermitIsSubmitted();
              this.PopulatePermitQnA();
            } else {
              const currentDate = new Date();
  
              let postPermit: Permit = {
                id: undefined,
                userFullName: undefined,
                userCompany: signInInfo.complianceCompany,
                permitNumber: undefined,
                workOrderNumber: workOrderNumber,
                descriptionOfWork: descriptionOfWork,
                additionalPeopleWork: undefined,
                estimateDurationFrom: `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:00`,
                estimateDurationTo: `${(currentDate.getHours() + 2).toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:00`,
                signInSignOutId: signInInfo.signInSignOutId,
                siteId: signInInfo.siteId,
                status: 0,
                createdAt: undefined,
                openPermit: undefined,
                welfareChecks: [],
                closePermit: undefined
              };
  
              this._permitService.createPermit(postPermit).subscribe({
                next: async (success) => {
                  this.currentPermit = success;
                  this.PopulatePermitQnA();
                },
                error: (error) => {
                  if (error.error && error.error.errorCode) {
                    alert(this._localizationService.translate(error.error.errorCode));
                  }
                  else {
                    alert(this._localizationService.translate("open_permit_error_create_permit"));
                  }
                  this._router.navigate(['siteinductioncompleted']);
                }
              });
            }
          },
          error: (error) => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate("open_permit_error_get_permit"));
            }
          }
        });
      } else {
        alert(this._localizationService.translate('open_permit_no_signin_found'));
        this._router.navigate(['signin']);
      }
    });
  }

  PopulatePermitQnA() {
    if (this.currentPermit) {
      this.permitInfoFormGroup.patchValue({
        workOrderNumber: this.currentPermit.workOrderNumber,
        descriptionOfWork: this.currentPermit.descriptionOfWork,
        additionalPeopleWork: this.currentPermit.additionalPeopleWork,
        estimateDurationFrom: this.currentPermit.estimateDurationFrom,
        estimateDurationTo: this.currentPermit.estimateDurationTo,
      });

      if (this.currentPermit.openPermit) {
        this.openPermitQnAs = JSON.parse(this.currentPermit.openPermit.qnA);
        if (this.openPermitQnAs.length <= 0) {
          alert(this._localizationService.translate("open_permit_no_questions_found"));
          this._router.navigate(['signin']);
        }
      }
    }

  }

  cancelOnClick() {
    if (confirm(this._localizationService.translate("open_permit_cancel_confirmation"))) {
      this._router.navigate(['siteinductioncompleted']);
    }
  }

  /**
   * Helper method for removing unnecessary properties from the permit questions JSON.
   * @param permitQnAs 
   *  JSON object containing permit questions
   * @returns 
   *  A stringified JSON object with all unnecessary properties removed.
   */
  private sanitizePermitQnA(permitQnAs: any): string {
    let permitQnASanitized = JSON.stringify(permitQnAs)
      .replace(this.hasErrorTrueRegex, '')
      .replace(this.hasErrorFalseRegex, '');

    return permitQnASanitized;
  }

  saveAsDraftOnClick() {
    if (this.currentPermit && this.currentPermit.openPermit != null) {

      this.currentPermit.workOrderNumber = this.permitInfoFormGroup.get('workOrderNumber')?.value || '';
      this.currentPermit.descriptionOfWork = this.permitInfoFormGroup.get('descriptionOfWork')?.value || '';
      this.currentPermit.additionalPeopleWork = this.permitInfoFormGroup.get('additionalPeopleWork')?.value || '';

      this.currentPermit.estimateDurationFrom = this.formatTime(this.permitInfoFormGroup.get('estimateDurationFrom')?.value);
      this.currentPermit.estimateDurationTo = this.formatTime(this.permitInfoFormGroup.get('estimateDurationTo')?.value);

      let permitQnASanitized = this.sanitizePermitQnA(this.openPermitQnAs);
      this.currentPermit.openPermit.qnA = this._utilsService.encodeBase64String(permitQnASanitized);

      this._permitService.draftPermit(this.currentPermit).subscribe({
        next: async (result) => {
          if (result != null) {
            alert(this._localizationService.translate("open_permit_save_draft_success"));
            this._router.navigate(['siteinductioncompleted']);
          }
          else {
            alert(this._localizationService.translate("open_permit_save_draft_error"));
          }
        },
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate("open_permit_save_draft_error"));
          }          
        }
      });
    }
    else {
      alert(this._localizationService.translate("open_permit_save_draft_error_current_permit_missing"));
    }

  }

  formatTime = (time: string): string => {
    if (!time) {
      return '00:00:00';
    }
    return time.length === 8 ? time : time + ':00';
  };

  checkIfOpenPermitIsSubmitted() {
    if (this.currentPermit?.status !== PermitStatus.DRAFT) {
      alert(this._localizationService.translate("open_permit_submit_permit_non_draft_status"));
      this._router.navigate(['siteinductioncompleted']);
    }
  }

  submitOnClick() {

    if (this.currentPermit && this.currentPermit.openPermit != null) {
      const woorkOrderNumberValue = this.permitInfoFormGroup.get('workOrderNumber')?.value;
      const descriptionOfWorkValue = this.permitInfoFormGroup.get('descriptionOfWork')?.value;
      const additionalPeopleWorkValue = this.permitInfoFormGroup.get('additionalPeopleWork')?.value;

      this.currentPermit.workOrderNumber = woorkOrderNumberValue ? woorkOrderNumberValue.trim() : '';
      this.currentPermit.descriptionOfWork = descriptionOfWorkValue ? descriptionOfWorkValue.trim() : '';
      this.currentPermit.additionalPeopleWork = additionalPeopleWorkValue ? additionalPeopleWorkValue.trim() : '';

      this.currentPermit.estimateDurationFrom = this.formatTime(this.permitInfoFormGroup.get('estimateDurationFrom')?.value);
      this.currentPermit.estimateDurationTo = this.formatTime(this.permitInfoFormGroup.get('estimateDurationTo')?.value);

      this.hasContentError = this.performErrorValidation(this.openPermitQnAs);

      if (!this.hasContentError) {
        let permitQnASanitized = this.sanitizePermitQnA(this.openPermitQnAs);
        this.currentPermit.openPermit.qnA = this._utilsService.encodeBase64String(permitQnASanitized);

        this._permitService.submitPermit(this.currentPermit).subscribe({
          next: async (result) => {
            if (result != null) {
              alert(this._localizationService.translate("open_permit_submit_permit_success"));
              this._router.navigate(['siteinductioncompleted']);
            }
            else {
              alert(this._localizationService.translate("open_permit_submit_permit_error"));
            }
          },
          error: (error) => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate("open_permit_submit_permit_error"));
            }
          }
        });
      }
      else {
        alert(this._localizationService.translate('open_permit_error_required_fields_empty'));
      }
    }
    else {
      alert(this._localizationService.translate("open_permit_submit_permit_error_current_permit_missing"));
      this._router.navigate(['siteinductioncompleted']);
    }

  }

  /**
   * Helper method for validating errors in the permit questions.
   * @param QnAs 
   *  JSON object containing permit questions
   * @returns 
   *  True if errors found, false otherwise.
   */
  performErrorValidation(QnAs: any): boolean {
    let errorsFound = false;

    if (QnAs) {
      QnAs.forEach((question: any) => {
        // Set value to false to reset change detection if value was previously true
        question.HasContentError = false;
        this.changeDetectorRef.detectChanges();

        if (question.IsRequired) {
          if (!question.Answer || question.Answer.trim() === "") {
            errorsFound = true;
            question.HasContentError = true;

            this.errorMessage = this._localizationService.translate('open_permit_error_required_fields_empty');
          }
          else {
            question.HasContentError = false;
          }

          this.changeDetectorRef.detectChanges();
        }

        // Validate nested Rules
        if (question.Rules !== null && question.Rules !== undefined && question.Rules.length > 0) {
          question.Rules.forEach((rule: any) => {
            if (String(question.Answer).includes(String(rule.Value))) {
              const validationResults = this.performErrorValidation(rule.RuleDetails);
              // Only allow errorsFound to switch value to true, and never from true to false
              errorsFound = validationResults ? validationResults : errorsFound;
            }
          });
        }

        // Validate nested Groups
        if (question.GroupingDetails !== null && question.GroupingDetails !== undefined && question.GroupingDetails.length > 0) {
          const validationResults = this.performErrorValidation(question.GroupingDetails);
          // Only allow errorsFound to switch value to true, and never from true to false
          errorsFound = validationResults ? validationResults : errorsFound;
        }
      });
    }

    return errorsFound;
  }
}
