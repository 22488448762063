import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClosePermit, Permit } from '@app/models/permit.model';
import { PermitService } from '@app/services/permit/permit-service';
import { UserService } from '@app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { SiteService } from '@app/services/site/site.service';
import { PermitStatus } from '@app/shared/enums';
import { UtilsService } from '@app/services/util/util-service';

@Component({
    selector: 'app-close-permit',
    templateUrl: './close-permit.component.html',
    styleUrls: ['./close-permit.component.scss'],
    standalone: false
})
export class ClosePermitComponent {
  openQuestion: any | [];
  currentPermit: Permit | undefined;
  closePermit: ClosePermit | undefined;
  questionArrays: any | [];
  locationOfWork: string = "";

  hasContentError: boolean = false;
  errorMessage: string = '';

  private hasErrorTrueRegex = /,"HasContentError":true/g;
  private hasErrorFalseRegex = /,"HasContentError":false/g;

  constructor(private _permitService: PermitService,
    private _localizationService: LocalizationService,
    private _router: Router,
    private _userService: UserService,
    private _navigationService: NavigationService,
    private _siteService: SiteService,
    private changeDetectorRef: ChangeDetectorRef,
    private _utilsService: UtilsService) {
    this._navigationService.onChange(false, false);
  }

  async ngOnInit(): Promise<void> {
    await this.getPermit();
  }

  async getPermit() {
    this._userService.getSignIn().then(signInInfo => {
      if (signInInfo && signInInfo.signInSignOutId) {
        this._permitService.getPermitWithFormBySignInSignOutId(signInInfo.signInSignOutId).subscribe({
          next: async (permit) => {
            if (permit) {
              this.currentPermit = permit;
             
              if(permit.openPermit?.qnA){
                this.openQuestion = JSON.parse(permit.openPermit?.qnA);
              }
             
              this.checkIfClosePermitIsSubmitted();
              await this.getNewClosePermit();
              await this.getSiteName();
            }
          }, error: (error) => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate("close_permit_fetching_permit_error"));
            }
            console.error(this._localizationService.translate('close_permit_fetching_permit_error', error));
          }
        });
      }
      else {
        alert(this._localizationService.translate('close_permit_error_NoSignIn_Found'));
        this._router.navigate(['signin']);
      }
    });
  }

  async getNewClosePermit() {
    const site = await this._siteService.getSignedInSite();
    if(site){
      this._permitService.getNewClosePermit(site.brand.id, site.id).subscribe({
        next: (closePermit) => {
          var closePermitQuestions = JSON.parse(closePermit.qnA);
          this.questionArrays =  this.generateFilteredDependentQuestions(closePermitQuestions);
        },
        error: (error) => {
          console.error(this._localizationService.translate('close_permit_fetching_close_permit_error', error));
        }
      });
    }else{
      alert(this._localizationService.translate('open_permit_no_site_found'));
    }
  }

  generateFilteredDependentQuestions(questions: any[]): any[] {
    var questionsToRender : any[] = [];
 
    questions.forEach((question: any) => {
      var tempQuestion = question;
      
      if(question.Rules && question.Rules.length > 0){
        var tempRules :any[] = [];
        question.Rules.forEach((rule: any) => {
          var tempRule = rule;
          tempRule.RuleDetails = this.generateFilteredDependentQuestions(rule.RuleDetails);
          if(tempRule.RuleDetails){
            tempRules.push(tempRule);
          }
        });

        if(tempRules && tempRules.length > 0){
          tempQuestion.Rules = tempRules;
        }else{
          tempQuestion = null;
        }

        if(tempQuestion){
          questionsToRender.push(tempQuestion);
        }

      }else if (question.GroupingDetails && question.GroupingDetails.length > 0){
        var tempGroupingDetails = this.generateFilteredDependentQuestions(question.GroupingDetails);
        if(tempGroupingDetails && tempGroupingDetails.length > 0){
          tempQuestion.GroupingDetails = tempGroupingDetails;
        }else{
          tempQuestion = null;
        }

        if(tempQuestion){
          questionsToRender.push(tempQuestion);
        }

      }else{
        var singleResult = this.isDependentQuestionContainAnswer(question);
        if(singleResult){
          questionsToRender.push(singleResult);
        }
      }
    });

    return questionsToRender;
  }

  isDependentQuestionContainAnswer(question: any): any {

    if(question.OpenPermitDependantId && question.OpenPermitDependantId > 0){
      const parentOpenQuestion = this.openQuestion.find((oQuestion: any) => oQuestion.Id === question.OpenPermitDependantId);
      if(parentOpenQuestion){
        const isAnswered = parentOpenQuestion.Answer?.toString().trim() && parentOpenQuestion.Answer?.toString().trim().length > 0;
        if(isAnswered)
          return question;
      }
    }else{
      return question;
    }

    return null;
  }

  async getSiteName() {
    this._siteService.getSignedInSite().then(site => {
      if (site) {
        this.locationOfWork = site.name;
      }
    });
  }

  checkIfClosePermitIsSubmitted() {
    if (this.currentPermit?.status !== PermitStatus.SUMBITTED) {
      alert(this._localizationService.translate("close_permit_error_NoClosePermit_Found"));
      this._router.navigate(['siteinductioncompleted']);
    }
  }

  updatePermit(permit: Permit | undefined) {
    if (permit && permit.closePermit) {
      permit.openPermit = undefined;
      permit.welfareChecks = [];
      this._permitService.updatePermit(permit).subscribe({
        next: (permit) => {
          if (permit) {
            alert(this._localizationService.translate('close_permit_success'));
            this._router.navigate(['siteinductioncompleted']);
          }
        },
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate("close_permit_create_answer_permit_error"));
          }
          console.error(this._localizationService.translate('close_permit_create_answer_permit_error', error));
        }
      });
    }
    else {
      alert(this._localizationService.translate('close_permit_error_NoClosePermit_Found'));
      this._router.navigate(['siteinductioncompleted']);
    }
  }

  onSubmit() {
    this.hasContentError = this.performErrorValidation(this.questionArrays);

    if (!this.hasContentError) {
      let permitQnASanitized = this.sanitizePermitQnA(this.questionArrays);
      const base64QuestionArrays = this._utilsService.encodeBase64String(permitQnASanitized);
      this.closePermit = {
        id: undefined,
        qnA: base64QuestionArrays,
        permitId: this.currentPermit?.id
      }
      if (this.currentPermit && this.closePermit) {
        this.currentPermit.closePermit = this.closePermit;
        this.updatePermit(this.currentPermit);
      }
    } else {
      alert(this._localizationService.translate('close_permit_required_fields_error'));
    }
  }

  performErrorValidation(QnAs: any): boolean {
    let errorsFound = false;

    if (QnAs) {
      QnAs.forEach((question: any) => {
        question.HasContentError = false;
        this.changeDetectorRef.detectChanges();

        if (question.IsRequired) {
          if (!question.Answer || question.Answer.trim() === "") {
            errorsFound = true;
            question.HasContentError = true;

            this.errorMessage = this._localizationService.translate('close_permit_required_fields_error');
          }
          else {
            question.HasContentError = false;
          }

          this.changeDetectorRef.detectChanges();
        }

        // Validate nested Rules
        if (question.Rules !== null && question.Rules !== undefined && question.Rules.length > 0) {
          question.Rules.forEach((rule: any) => {
            if (String(question.Answer).includes(String(rule.Value))) {
              errorsFound = this.performErrorValidation(rule.RuleDetails);
            }
          });
        }

        // Validate nested Groups
        if (question.GroupingDetails !== null && question.GroupingDetails !== undefined && question.GroupingDetails.length > 0) {
          errorsFound = this.performErrorValidation(question.GroupingDetails);
        }
      });
    }

    return errorsFound;
  }

  private sanitizePermitQnA(permitQnAs: any): string {

    if (!permitQnAs) {
      return '';
    }

    let permitQnASanitized = JSON.stringify(permitQnAs)
      .replace(this.hasErrorTrueRegex, '')
      .replace(this.hasErrorFalseRegex, '');

    return permitQnASanitized;
  }

  onCancel() {
    this._router.navigate(['siteinductioncompleted']);
  }

}
