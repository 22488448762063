import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RapidGlobalInfo, User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
    selector: 'app-rapid-global-delete',
    templateUrl: './rapid-global-delete.component.html',
    styleUrls: ['./rapid-global-delete.component.scss'],
    standalone: false
})
export class RapidGlobalDeleteComponent implements OnInit {
  rapidGlobalInfo : RapidGlobalInfo | undefined;
  currentUser : User | undefined;

  constructor(private _dialogRef: MatDialogRef<RapidGlobalDeleteComponent>,
    private _userService : UserService,
    private _localizationService : LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: User | undefined, rapid: RapidGlobalInfo | undefined }
    ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.rapidGlobalInfo = this.data.rapid;
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  deleteRapidGlobal(){
    if (this.rapidGlobalInfo && this.rapidGlobalInfo.id){
      if (this.currentUser){
        this._userService.deleteRapidGlobal(this.rapidGlobalInfo.id).subscribe({
          next: (_ => {
            const indx = this.currentUser?.rapidGlobalInfos
              .findIndex(v => v.id === this.rapidGlobalInfo?.id) ?? -1;
            this.currentUser?.rapidGlobalInfos.splice(indx, indx >= 0 ? 1 : 0);
            this._dialogRef.close(this.currentUser);
          }),
          error: (error => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate('register_rapidglobal_delete_error'));
            }
          }) 
        });
      }
    }
  }  
}