import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { QnaTranslatePipe } from '@app/pipes/qna-translate.pipe';
import { PermitService } from '@app/services/permit/permit-service';

@Component({
    selector: 'app-permit-multiple-choice',
    templateUrl: './permit-multiple-choice.component.html',
    styleUrls: ['./permit-multiple-choice.component.scss'],
    standalone: false
})
export class PermitMultipleChoiceComponent implements OnInit {

  @Input('question') question : any;
  @Input() hasContentError: boolean = false;
  indexSelected : boolean[] = [];
  options : string[] = [];
  translatedOption: string[] = [];
  answerTouched: boolean = false;
  isRequiredMessageDisplay: string = '';

  constructor(private _permitService: PermitService, private _qnaTranslatePipe: QnaTranslatePipe){}

  ngOnInit(): void {
    var answer : string[] = [];

    if (this.question && this.question.QuestionDetails){
      this.options = this.question.QuestionDetails.split('|');
      
      this.translatedOption = this.question.QuestionDetailsTranslation ? 
        this._qnaTranslatePipe.transform(this.question.QuestionDetailsTranslation, this.question.QuestionDetails).split('|') : this.options;

      if (this.question.Answer === null || this.question.Answer === undefined) {
        answer = [];
      } else {
        answer = this.question.Answer.split("|");
      }
      if (answer !== undefined) {
        this.options.forEach((option: string) => {
          if (answer.indexOf(option) > -1) {
            this.indexSelected.push(true);
          } else {
            this.indexSelected.push(false);
          }         
        });
      }
    }

    if(this.question.IsRequired)
    {
      this.isRequiredMessageDisplay = this._permitService
        .determineIsRequiredMessage(this._qnaTranslatePipe.transform(this.question.IsRequiredMessage));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.question.HasContentError && this.question.IsRequired && !this.question.Answer){
      this.answerTouched = true;
    }
  }

  captionStyles() : object {
    return JSON.parse(this.question.CaptionStyles);
  }

  questionDetailsStyles() : object {
    return JSON.parse(this.question.QuestionDetailsStyles);
  }

  setAnswer(event: any) {
    var answer: string[] = [];
    let originalAnswer: string[] = [];
    
    if (this.question.Answer === null || this.question.Answer === undefined) {
      this.question.Answer = null;
    } else {
      answer = this.question.Answer.split("|");
      originalAnswer = this.question.Answer.split("|");
    }
    
    var valueFound = answer.indexOf(event.source.value);
    if (event.checked) {
      if (valueFound == -1) {
          answer.push(event.source.value);
      }
    } else {
      if (valueFound > -1) {
        answer.splice(valueFound, 1);

        this._permitService.clearSubQuestionAnswers(this.question, true, originalAnswer[valueFound]);
      }
    }

    if(answer && answer.length > 0) {
      this.question.Answer  = answer.map((x:string) => x).join("|");
    }
    else{
      this.question.Answer = null;
    }

    this.answerTouched = true;
  }

  expendChilds(value: string):boolean {
    var rule = null;
    if(this.question.Answer){
      var answers = this.question.Answer.split("|");
      rule = answers.find((a: any) => a.trim().toLowerCase() === value.toLowerCase());
    }
     
    return rule && rule !== undefined && rule !== null;
  }

  getRuleDetails(value: string) {
    const rule = this.question.Rules.find((rule: any) => rule.Value.trim().toLowerCase() === value.toLowerCase());
    if (rule && rule.RuleDetails !== null && rule.RuleDetails.length > 0) {
      return rule.RuleDetails;
    }
  }
}
