import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LocalizationServiceConfig } from './localization-config.service';
import { catchError, firstValueFrom, Observable, tap } from 'rxjs';
import { StorageKey } from '@app/shared/enums';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@app/services/base/base.service';
import { Language } from '@app/models/language.model';
import { Preferences } from '@capacitor/preferences';

/**
 * Class representing the translation service.
 */
@Injectable()
export class LocalizationService extends BaseService {
  private _localeId: string = environment.defaultLang; // default
  readonly KEY = StorageKey[StorageKey.LANGUAGE];
  readonly KEY_AZURELOCALE = StorageKey[StorageKey.AZURELOCALE];
  readonly BASE_URL = `${environment.gatewayAPI_V1}/References/`

  /**
   * @constructor
   * @param {LocalizationService} singleton - the localization service
   * @param {LocalizationServiceConfig} config - the localization config
   * @param {TranslateService} translateService - the translate service
   */
  constructor(
    @Optional() @SkipSelf() private singleton: LocalizationService,
    private config: LocalizationServiceConfig,
    private translateService: TranslateService,
    private httpClient: HttpClient
  ) {
    super();

    if (this.singleton) {
      throw new Error(
        'LocalizationService is already provided by the root module'
      );
    }
    this._localeId = this.config.locale_id;
  }

  /**
   * Initialize the service.
   * @returns {Promise<void>}
   */
  public initService(): Promise<void> {
    this._localeId = this.getNoAsync() || this._localeId;
    return this.useLanguage(this._localeId);
  }

  /**
   * change the selected language
   * @returns {Promise<void>}
   */
  public useLanguage(lang: string): Promise<void> {

    lang = lang || this._localeId;

    this.translateService.setDefaultLang(lang);
    return firstValueFrom(this.translateService.use(lang)).then(() => 
    { }).catch(() => {  
      throw new Error('LocalizationService.init failed'); 
    });
  }

  /**
   * Gets the instant translated value of a key (or an array of keys).
   * @param key
   * @param interpolateParams
   * @returns {string|any}
   */
  public translate(key: string | string[], interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams) as string;
  }

  public getLanguages(): Observable<Language[]>{
    const url = `${this.BASE_URL}Language/GetLanguages`;

    return this.httpClient.get<Language[]>(url)
      .pipe(tap(_ => _),
        catchError(this.handleError));
  }

  public async set(version: string)
  {
    await Preferences.set({
      key: this.KEY,
      value: version
    });
  }

  public async get(): Promise<string>
  {
    const item = await Preferences.get({ key: this.KEY });
    if(item.value != null)
    {
      return item.value
    }

    return '';
  }

  public getNoAsync(): string
  {
    const item = localStorage.getItem("CapacitorStorage." + StorageKey[StorageKey.LANGUAGE]);
    if(item != null)
    {
      return item;
    }

    return '';
  }

  public async setAzureLocale(locale: string)
  {
    await Preferences.set({
      key: this.KEY_AZURELOCALE,
      value: locale
    });
  }

  public async getAzureLocale(): Promise<string>
  {
    const item = await Preferences.get({ key: this.KEY_AZURELOCALE });
    if(item.value != null)
    {
      return item.value
    }

    return '';
  }
}
