import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { VisitReason } from '@app/models/visitreason.model';
import { ReferenceService } from '@app/services/reference/reference.service';

@Component({
    selector: 'app-reason-for-visit-delete',
    templateUrl: './reason-for-visit-delete.component.html',
    styleUrls: ['./reason-for-visit-delete.component.scss'],
    standalone: false
})
export class ReasonForVisitDeleteComponent implements OnInit {

  visitreason: VisitReason | undefined;
  deletePopupHeader: string = "";

  constructor(private _localizationService: LocalizationService,
    private _referenceService: ReferenceService,
    private _dialogRef: MatDialogRef<ReasonForVisitDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      reason: VisitReason | undefined;
    }
  ) { }

  ngOnInit(): void {
    this.visitreason = this.data.reason;
    this.setHeader();
  }

  setHeader() {
    if (this.visitreason) {
      this.deletePopupHeader = this._localizationService.translate("visitor_type_management_reason_for_visit_delete_popup_header")
        .replace('{visitReasons}', this.visitreason?.description);
    }
  }

  deleteVisitReasons() {
    if (this.visitreason && this.visitreason.id && this.visitreason.id !== '' ) {
      this._referenceService.deleteVisitReason(this.visitreason.id).subscribe({
        next: (result) => {
          if (result) {
            this.dismissDialog(result);
          }
        },
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate("visitor_type_management_reason_for_visit_delete_snackbar_delete_error"));
          }
          console.error(this._localizationService.translate("visitor_type_management_reason_for_visit_delete_snackbar_delete_error"), error);
        }
      })
    }
  }

  dismissDialog(result?: boolean) {
    this._dialogRef.close(result);
  }

}
