import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-permit-question-render',
    templateUrl: './permit-question-render.component.html',
    styleUrls: ['./permit-question-render.component.scss'],
    standalone: false
})

export class PermitQuestionRenderComponent implements OnInit {

  @Input() question : any = '';
  @Input() hasContentError: boolean = false;
  constructor() { 
    
  }

  ngOnInit(): void {
  }
}

