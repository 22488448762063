import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { UserDocument } from 'src/app/models/user.model';
import { User } from 'src/app/models/user.model';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
    selector: 'app-user-documents-add',
    templateUrl: './user-documents-add.component.html',
    styleUrls: ['./user-documents-add.component.scss'],
    standalone: false
})
export class UserDocumentsAddComponent implements OnInit {
  currentUser: User | undefined;
  
  constructor(
    private _dialogRef: MatDialogRef<UserDocumentsAddComponent>,
    private _localizationService: LocalizationService,
    private _documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: User | undefined, 
      docType: string,
      docTypeData: UserDocument[] | undefined }
  ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  importFile(event: any){
    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return;
    }
          
    let file: File = event.target.files[0];

    if (this.data.docTypeData && this.data.docTypeData.length > 0){
      let attachment = this.data.docTypeData.find((att : UserDocument) => att.documentName.toLowerCase() == file.name.toLowerCase());
      if (attachment){
        alert(this._localizationService.translate("user_documents_addfile_invalid_samefile"));
        return;
      }
    }    

    let userDocumentUpload = this._documentService.getFileAndUploadType(file);

    if (userDocumentUpload){
      (userDocumentUpload.UploadType === 'img'
        ? this._documentService.uploadUserImage(userDocumentUpload.FileType, this.data.docType, file)
        : this._documentService.uploadUserDocument(userDocumentUpload.FileType, this.data.docType, file)
      ).subscribe({
        next: (response => {
          if (response) this.currentUser?.userDocuments.push(response);
          this._dialogRef.close(this.currentUser);
        })
      });
    }

  }
}