import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { QnAQuestionAttachmentModel } from 'src/app/models/inductionqna.model';
import { CustomValidators } from 'src/app/shared/custom-validators';

@Component({
    selector: 'app-cityonsite-attachment-external-link',
    templateUrl: './cityonsite-attachment-external-link.component.html',
    styleUrls: ['./cityonsite-attachment-external-link.component.scss'],
    standalone: false
})
export class CityonsiteAttachmentExternalLinkComponent implements OnInit {

  public extLinkFormGroup!: UntypedFormGroup;
  questionAttachments : QnAQuestionAttachmentModel[] = [];
  externalLink : string = "EXTERNAL";

  constructor(private _dialogRef: MatDialogRef<CityonsiteAttachmentExternalLinkComponent>,
    private _localizationService : LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.extLinkFormGroup = this.initFormGroup();
    if(this.data.question.questionAttachments !== undefined && this.data.question.questionAttachments !== null){
      this.questionAttachments = this.data.question.questionAttachments;
    }
  }

  private initFormGroup(): UntypedFormGroup {
    const externalLinkForm = this._formBuilder.group({
      name: new UntypedFormControl(undefined, [Validators.required]),
      externalLink: new UntypedFormControl(undefined, Validators.compose([CustomValidators.isFileUrlValid(), Validators.required])),
    });
    return externalLinkForm;
  }

  dismissDialog(): void {
    this._dialogRef.close();
  }

  addlink(){

    if (this.questionAttachments.length >= 10){
      alert(this._localizationService.translate("cityonsiteattachment_addfile_invalid_maxnumber"));
      return;
    }
    
    const inputFileName = this.extLinkFormGroup.get('name')?.value;
    const inputExternalLink = this.extLinkFormGroup.get('externalLink')?.value;

    if (inputFileName.trim() === "" || inputExternalLink.trim() === ""){
      alert(this._localizationService.translate("cityonsiteattachment_addextlink_invalid_emptyvalue"));
      return;
    }

    if (this.questionAttachments.length > 0){
      let attachment = this.questionAttachments.find((att : any) => att.fileName.toLowerCase() == inputFileName.toLowerCase());
      if (attachment){
        alert(this._localizationService.translate("cityonsiteattachment_addextlink_invalid_samefilename"));
        return;
      }
    }
   
    let questionAttachment = {
      activityTaskInstanceJobEventQnAFileId : "",
      fileName : inputFileName,
      fileByteArray : "",
      data : undefined,
      fileType : this.externalLink,
      htmlTag : inputExternalLink,
      fileGuid : ""
    }
    this.questionAttachments.push(questionAttachment);
    this.data.question.questionAttachments = this.questionAttachments
    this._dialogRef.close(this.data);
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'name':
        if (this.formHasError(form, controlName, 'required')) {
          return 'File name is required';
        } 
        break;
      case 'externalLink':  
        if (this.formHasError(form, controlName, 'required')) {
          return 'External link is required';
        } else 
          if (this.formHasError(form, controlName, 'invalidFileUrl')) {
            return 'Valid URL is required';
          }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  public disableAddLinkButton(): boolean {
    return !this.extLinkFormGroup.valid;
  }
  
}