import { Component, Input, ViewChild, AfterViewInit, ElementRef, SimpleChanges, viewChild, OnInit } from '@angular/core';
import { PermitService } from '@app/services/permit/permit-service';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { QnaTranslatePipe } from '@app/pipes/qna-translate.pipe';

@Component({
    selector: 'app-permit-signature',
    templateUrl: './permit-signature.component.html',
    styleUrls: ['./permit-signature.component.scss'],
    standalone: false
})
export class PermitSignatureComponent implements OnInit {

  @Input('question') question : any;

  @ViewChild('signatureWrapper') signatureWrapper: ElementRef | undefined;
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  @Input() hasContentError: boolean = false;
  answerTouched: boolean = false;
  isRequiredMessageDisplay: string = '';

  signaturePadOptions: NgSignaturePadOptions = { 
    minWidth: 2,
    canvasWidth: window.innerWidth * 0.92,
    canvasHeight: 150,
    canvasBackground: 'white'
  };

  constructor(private _permitService: PermitService, private _qnaTranslatePipe: QnaTranslatePipe) { }

  ngOnInit(): void {
    if (this.question){
      if(this.question.IsRequired)
      {
        this.isRequiredMessageDisplay = this._permitService.determineIsRequiredMessage(this._qnaTranslatePipe.transform(this.question.IsRequiredMessage));
      }

      if (this.question.Answer === null || this.question.Answer === undefined || this.question.Answer === "") 
      {
        this.signaturePad?.clear();
      } else {
        this.signaturePad?.clear();
        this.signaturePad?.fromDataURL(this.question.Answer);
      }

      this.setSignaturePad();
      
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.question.HasContentError && this.question.IsRequired && !this.question.Answer){
      this.answerTouched = true;

      if(this.signatureWrapper && this.signaturePad && this.question.IsRequired){
        this.signatureWrapper.nativeElement.classList.remove('signaturewrapper');
        this.signatureWrapper.nativeElement.classList.add('signaturewrapper-error');
      }
    }
  }

  drawComplete(event: MouseEvent | Touch) {
    this.question.Answer = this.signaturePad?.toDataURL();

    if(!this.answerTouched)
    {
      this.answerTouched = true;
    }

    if(this.signatureWrapper && this.signaturePad){
      this.signatureWrapper.nativeElement.classList.remove('signaturewrapper-error');
      this.signatureWrapper.nativeElement.classList.add('signaturewrapper'); 
    }
  }

  drawStart(event: MouseEvent | Touch) {
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.question.Answer = "";

    if(this.signatureWrapper && this.signaturePad && this.question.IsRequired){
      this.signatureWrapper.nativeElement.classList.remove('signaturewrapper');
      this.signatureWrapper.nativeElement.classList.add('signaturewrapper-error');
    }

    if(!this.answerTouched)
    {
      this.answerTouched = true;
    }
  }

  private getCanvasElement(): HTMLCanvasElement {
    return this.signatureWrapper!.nativeElement.querySelector('canvas');
  }

  setSignaturePad() {
    if (this.signatureWrapper && this.signaturePad) {
      this.signaturePad.getCanvas().width = this.signatureWrapper.nativeElement.clientWidth - 1;
      this.signaturePad.getCanvas().height = this.signatureWrapper.nativeElement.clientHeight - 1;
    }
  }

  resizeSignaturePad() {
    this.setSignaturePad();
    this.clearSignature();
  }

  captionStyles() : object {
    return JSON.parse(this.question.CaptionStyles);
  }

  questionDetailsStyles() : object {
    return JSON.parse(this.question.QuestionDetailsStyles);
  }
}
