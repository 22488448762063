<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="0px">
    <div>
        <mat-label class="textBoxLabel" [ngStyle]="captionStyles()">
            {{ question.Caption | qnaTranslate }} 
            <mat-label *ngIf="question.IsRequired" class="color-city-red" > *</mat-label>
        </mat-label>
    </div>
    <mat-form-field appearance="outline" class="textWrapper">
        <div *ngIf="!question.IsMultiLine; else multiLineComment">
            <input matInput 
                [formControl]="textFormControl"        
                (change)="saveComment(textFormControl.value)" [required]="question.IsRequired"> 
        </div>
        <ng-template #multiLineComment>
            <textarea matInput
                [formControl]="textFormControl"
                (change)="saveComment(textFormControl.value)" [required]="question.IsRequired">
            </textarea>
        </ng-template>
    </mat-form-field>
    <mat-error *ngIf="question.IsRequired && !question.Answer && textFormControl.touched">
        {{isRequiredMessageDisplay}}
    </mat-error>
</div>