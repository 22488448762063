import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserDocument } from 'src/app/models/user.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserDocumentsAddComponent } from '../user-documents-add/user-documents-add.component';
import { UserDocumentsDeleteComponent } from '../user-documents-delete/user-documents-delete.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-user-documents-list',
    templateUrl: './user-documents-list.component.html',
    styleUrls: ['./user-documents-list.component.scss'],
    standalone: false
})
export class UserDocumentsListComponent implements OnInit {
  currentUser: User | undefined;
  documentsSWMS: MatTableDataSource<UserDocument> = new MatTableDataSource<UserDocument>([]);
  documentsLicences: MatTableDataSource<UserDocument> = new MatTableDataSource<UserDocument>([]);
  documentsCert: MatTableDataSource<UserDocument> = new MatTableDataSource<UserDocument>([]);
  displayedDocumentColumns: string[] = ['documentName','rowbutton'];

  constructor(
    private _authService: AuthService,
    public _router: Router,
    private _addFileDialog: MatDialog    
    ) {
      this.loadUser();
    }

  private loadUser(): Promise<void> {
    return this._authService.getUser().then(
      user => {
        this.currentUser = user;
        this.documentsSWMS.data = user.userDocuments.filter(ud => ud.documentType === "SWMS");
        this.documentsLicences.data = user.userDocuments.filter(ud => ud.documentType === "License");
        this.documentsCert.data = user.userDocuments.filter(ud => ud.documentType === "Cert");
      },
      _ => console.log("Failed to get user")
    );
  }

  ngOnInit(): void {}

  attachFile(docType: string){
    let addFileDialog: MatDialogRef<UserDocumentsAddComponent>;
    let docTypeData;
    switch (docType) {
      case 'SWMS':
        docTypeData = this.documentsSWMS.data;
        break;
      case 'License':
        docTypeData = this.documentsLicences.data;
        break;
      case 'Cert':
        docTypeData = this.documentsCert.data
        break;
      default:
        break;
    }
    
    const dialogConfig = {
      data: {
        user: this.currentUser, 
        docType: docType, 
        docTypeData: docTypeData
      },
      disableClose: true,
      autoFocus: false
    };
    addFileDialog = this._addFileDialog.open(UserDocumentsAddComponent, dialogConfig);
    addFileDialog.addPanelClass('details-container');
    addFileDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser();
      }
    });
  }

  deleteFile(document: UserDocument | undefined){
    let deleteFileDialog: MatDialogRef<UserDocumentsDeleteComponent>;
    const dialogConfig = {
      data: {
        user: this.currentUser, 
        userDocument: document
      },
      disableClose: true,
      autoFocus: false,
      panelClass:'details-container'
    };
    deleteFileDialog = this._addFileDialog.open(UserDocumentsDeleteComponent, dialogConfig);
    deleteFileDialog.addPanelClass('details-container');
    deleteFileDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser();
      }
    });
  }

}