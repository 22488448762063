import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    standalone: false
})
export class LabelComponent implements OnInit {

  @Input('question') question : any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
