const b2cUrl = 'https://cityonsitedev.b2clogin.com/cityonsitedev.onmicrosoft.com';

export const environment = {
  production: false,
  appVersion: "1.2.1",
  maxFileSize : 10,
  maxSWMSFileUploadTotal: 10,
  appEnvironment: 'Dev',
  isDemo:false,
  defaultLang: "en-US",
  defaultAzureLocale: "en",
  appName: 'cityonsite',
  appDomain:"onsite-dev.cityfm.com.au",
  gatewayAPI_V1:"https://onsite-dev.cityfm.com.au/api/v1/gateway",
  capchaKey:"6LcWB_0hAAAAAFOTxPOa-ZGlfUqiS3vi003Z8Ghz",
  b2cSignInPolicy: "B2C_1A_CITYONSITE_Signin",
  b2cSignInWithApplePolicy: "B2C_1A_CITYONSITE_APPLESIGNUPORSIGNIN",
  b2cSignUpPolicy: "B2C_1A_CITYONSITE_SIGNUP",
  b2cGoogleSignUpOrSignin: "B2C_1A_CITYONSITE_GOOGLESIGNUPORSIGNIN",
  b2cEditProfilePolicy: "B2C_1A_CITYONSITE_PROFILEEDIT",
  b2cDeactivateProfilePolicy: "B2C_1A_CITYONSITE_DEACTIVATEPROFILE",
  b2cForgotPasswordPolicy: "B2C_1A_CITYONSITE_FORGOTPASSWORD",
  inductionModuleEnable: true,
  authB2C: {
    authorizationBaseUrl: `${b2cUrl}/{azure_policy}/oauth2/v2.0/authorize`,
    accessTokenEndpoint: `${b2cUrl}/{azure_policy}/oauth2/v2.0/token`,
    signOutRequestUrl: `${b2cUrl}/{azure_policy}/oauth2/v2.0/logout`,
    resetPasswordUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_CITYONSITE_FORGOTPASSWORD`,
    editProfileUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_CITYONSITE_PROFILEEDIT`,
    deactivateProfileUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_CITYONSITE_DEACTIVATEPROFILE`,
    appId: '7913fd36-60a0-4e63-8a7b-293a7cbb1dea',
    scope: '7913fd36-60a0-4e63-8a7b-293a7cbb1dea offline_access'
  },
  geolocationInterval: 60000,
  appVersionCheckInterval: 0, // In hours
  geofencingRangeMeterDefault: 5000,
  webGeolocationWatchEnableHighAccuracy: false,
  webGeolocationWatchMaximumAge: 30000,
  webGeolocationWatchTimeout: 30000,
  mobileGeolocationWatchBackgroundMessage: "City Onsite is tracking your location as you are logged into the site.",
  mobileGeolocationWatchBackgroundTitle: "City Onsite Tracker",
  mobileGeolocationWatchRequestPermissions: true,
  mobileGeolocationWatchStale: false,
  mobileGeolocationWatchDistanceFilter: 1,
  isSiteListJobCountEnable:true,
  isServiceWorkerEnabled: true,
  blockSearchIndexing: true,
  appInsights: {
    //instrumentationKey: '9191d81a-8a00-4cfd-8e95-4ea77ad44456',
    instrumentationKey: '',
  },
  configurationCheckInterval: 1, // In hours
  qrCodeLoginUrl: 'https://onsite-dev.cityfm.com.au/login',
};