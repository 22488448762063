import { Component, Input, OnInit } from '@angular/core';
import { QnAQuestionAttachmentModel } from 'src/app/models/inductionqna.model';
import { LocalizationService } from '../../internationalization/localization.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CityonsiteAttachmentsAddFileComponent } from './cityonsite-attachments-add-file/cityonsite-attachments-add-file.component';
import { DocumentService } from 'src/app/services/document/document.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { UtilsService } from '@app/services/util/util-service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-cityonsite-attachments',
    templateUrl: './cityonsite-attachments.component.html',
    styleUrls: ['./cityonsite-attachments.component.scss'],
    standalone: false
})
export class CityonsiteAttachmentsComponent implements OnInit {

  @Input('question') question : any;
  dataSource : MatTableDataSource<QnAQuestionAttachmentModel> = new MatTableDataSource<QnAQuestionAttachmentModel>([]);
  displayDocumentHeader : string | undefined;
  
  displayedDocumentColumns: string[] = ['fileName','rowbuttons' ];
  constructor(
    private _localizationService : LocalizationService,
    private _documentService: DocumentService,
    private _utilsService : UtilsService,
    private _addFileDialog: MatDialog
  ){}

  ngOnInit(): void {
    if (this.question){
      this.dataSource = new MatTableDataSource<QnAQuestionAttachmentModel>([]);
      if (this.question.question.questionAttachments && this.question.question.questionAttachments.length > 0){
        this.dataSource.data = this.question.question.questionAttachments;
        this.displayDocumentHeader = this._localizationService.translate("cityonsiteattachment_document_table_header");
      }
      else {
        this.displayDocumentHeader = this._localizationService.translate("cityonsiteattachment_providedocument_table_header");
      }
    }
  }

  attachFile(){
    let addFileDialog: MatDialogRef<CityonsiteAttachmentsAddFileComponent>;
    const dialogConfig = {
        data: this.question,
        disableClose: true,
        autoFocus: false
    };

    addFileDialog = this._addFileDialog.open(CityonsiteAttachmentsAddFileComponent, dialogConfig);
    addFileDialog.addPanelClass('details-container');
    addFileDialog.afterClosed().subscribe((returnValue) => {
        if (returnValue) {
          this.question = returnValue;
          this.ngOnInit();
        }
    });
  }

  delete(item: any){
    if(confirm(`${item.fileName} will be removed. Continue?`)) {
      if (this.question.question.questionAttachments){
        this.question.question.questionAttachments = this.question.question.questionAttachments.filter((doc : any) => doc.fileName != item.fileName);
        this.ngOnInit();
      }
    }    
  }

  download(item: QnAQuestionAttachmentModel){
    if(confirm(`${item.fileName} will be downloaded. Continue?`)) {    
      this._documentService.downloadUserDocument(item.fileGuid!)
      .subscribe({
        next: (async dataUrl => { 
          if (dataUrl != null){
            if (this._utilsService.isMobileAndTablet()){
              var base64String = dataUrl.substring(dataUrl.indexOf('base64') + 7);
              await Filesystem.writeFile({
                path: item.fileName,
                data: base64String,
                directory: Directory.Documents
              });
            }
            else {
              let link = document.createElement('a');
              link.setAttribute('type', 'hidden');
              link.href = dataUrl;
              link.download = item.fileName;
              document.body.appendChild(link);
              link.click();
              link.remove();
            }          
          } else {
            alert(this._localizationService.translate("user_documents_downloadfile_no_data_url_error"));
          }
        }),
        error: e => console.log(JSON.stringify(e))        
      });
    }
  }
}