import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
    selector: 'app-register-step-one',
    templateUrl: './register-step-one.component.html',
    styleUrls: ['./register-step-one.component.scss'],
    standalone: false
})
export class RegisterStepOneComponent implements OnInit {
  public formGroup!: UntypedFormGroup;
  public hasSendCode = false;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _localizationService: LocalizationService,
    private _router: Router) { 

    this.formGroup = this.initFormGroup();
  }

  ngOnInit(): void {
  }

  private initFormGroup(): UntypedFormGroup {
    const formGroup = this._formBuilder.group({
      emailAddress: new UntypedFormControl(undefined, [Validators.required, Validators.email]),
      code: new UntypedFormControl(undefined, [Validators.required]),
      firstname: new UntypedFormControl(undefined, [Validators.required]),
      lastname: new UntypedFormControl(undefined, [Validators.required]),
      mobile: new UntypedFormControl(undefined, [Validators.required]),
      password: new UntypedFormControl(undefined, [Validators.required]),
      confirmpassword: new UntypedFormControl(undefined, [Validators.required])
    },
    {
      validator: this.ConfirmedValidator('password', 'confirmpassword'),
    });

    return formGroup;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'emailAddress':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('register_lbl_one_email_required');
        } else if (this.formHasError(form, controlName, 'email')) {
          return this._localizationService.translate('loginuser_email_invalid');
        }
        break;
      case 'code':
      case 'firstname':
      case 'lastname':
      case 'mobile':
      case 'password':
      case 'confirmpassword':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('register_lbl_one_required');
        }
        else if (this.formHasError(form, controlName, 'confirmedValidator')) {
          return this._localizationService.translate('register_lbl_one_passwordnotmatch');
        }
        break;
    }
    return '';
  }

  sendCode(){
    this.hasSendCode = true;
  }

  disableCodeButton():boolean{
    return this.hasError(this.formGroup,'emailAddress');
  }

  disableContinueButton():boolean{
    return this.disableCodeButton() || !this.formGroup.valid;
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  cancelOnClick(){
    this._router.navigate(['login']);
  }

  continueOnClick(){
    this._router.navigate(['register/2']);
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }
}
