import { Component, Input, OnInit } from '@angular/core';
import { QnaTranslatePipe } from '@app/pipes/qna-translate.pipe';
import { PermitService } from '@app/services/permit/permit-service';

@Component({
    selector: 'app-permit-radiobutton',
    templateUrl: './permit-radiobutton.component.html',
    styleUrls: ['./permit-radiobutton.component.scss'],
    standalone: false
})
export class PermitRadioButtonComponent implements OnInit {

  @Input('question') question: any;
  @Input() hasContentError: boolean = false;
  options: string[] = [];
  translatedOption: string[] = [];
  selectedOption: string;
  nextQuestions: any;
  isRequiredMessageDisplay: string = '';

  constructor(private _permitService: PermitService, private _qnaTranslatePipe: QnaTranslatePipe) { }
  ngOnInit(): void {
    if (this.question && this.question.QuestionDetails) {
      this.options = this.question.QuestionDetails.split('|');
      
      this.translatedOption = this.question.QuestionDetailsTranslation ? 
      this._qnaTranslatePipe.transform(this.question.QuestionDetailsTranslation, this.question.QuestionDetails).split('|') : this.options;
    
      if(this.question.Answer !== null && this.question.Answer !== undefined)
      {
        this.onOptionSelected(this.question.Answer, false);
      }
    }

    if(this.question.IsRequired)
    {
      this.isRequiredMessageDisplay = this._permitService
        .determineIsRequiredMessage(this._qnaTranslatePipe.transform(this.question.IsRequiredMessage));
    }
  }

  onOptionSelected(option: string, clear: boolean = true): void {
    const originalAnswer = this.question.Answer;

    this.selectedOption = option;
    this.question.Answer = option;

    if (this.question.Rules !== null && this.question.Rules.length > 0) {
      const rule = this.question.Rules.find((rule: any) => rule.Value.trim().toLowerCase() === this.selectedOption.trim().toLowerCase());
      if (rule.RuleDetails !== null && rule.RuleDetails.length > 0) {
        this.nextQuestions = rule.RuleDetails;
      } else {
        this.nextQuestions = null;
      }
    }

    if(originalAnswer !== null && clear){
      // Clear subquestion answers of the previously selected answer
      this._permitService.clearSubQuestionAnswers(this.question, true, originalAnswer);
    }
  }

  captionStyles(): object {
    return JSON.parse(this.question.CaptionStyles);
  }

  questionDetailsStyles() : object {
    return JSON.parse(this.question.QuestionDetailsStyles);
  }
}
