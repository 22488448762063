import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
    standalone: false
})
export class InformationComponent implements OnInit {

  @Input('question') question : any;
  
  constructor() { }

  ngOnInit(): void {

  }
  
}