import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'app-user-documents',
    templateUrl: './user-documents.component.html',
    styleUrls: ['./user-documents.component.scss'],
    standalone: false
})
export class UserDocumentsComponent implements OnInit {  

  constructor(
    public _router: Router,
    private _navigationService:NavigationService) {      
      this._navigationService.onChange(true, false); 
    }

  ngOnInit(): void {}

  public closeUserDocuments(): void {
    this._router.navigate(['signin']);
  } 
  
}