import { Component, Input, OnInit } from '@angular/core';
import { PermitQnA } from '@app/models/permitqna.model';

@Component({
    selector: 'app-permit-group',
    templateUrl: './permit-group.component.html',
    styleUrls: ['./permit-group.component.scss'],
    standalone: false
})
export class PermitGroupComponent implements OnInit {
  runningQuestions : any = [];
  @Input() hasContentError : any;

  @Input('question') question : any;

  constructor(){}

  ngOnInit(): void {
    if (this.question.GroupingDetails !== null && this.question.GroupingDetails.length > 0) {
      this.runningQuestions = this.question.GroupingDetails;
    }
  }

  captionStyles() : object {
    return JSON.parse(this.question.CaptionStyles);
  }
}
