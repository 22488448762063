import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { Language } from '@app/models/language.model';
import { Translation } from '@app/models/translation.model';
import { VisitorType } from '@app/models/visitortype.model';
import { VisitReason } from '@app/models/visitreason.model';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { ReferenceService } from '@app/services/reference/reference.service';
import { environment } from '@env/environment';

@Component({
    selector: 'app-reason-for-visit-add-edit',
    templateUrl: './reason-for-visit-add-edit.component.html',
    styleUrls: ['./reason-for-visit-add-edit.component.scss'],
    standalone: false
})
export class ReasonForVisitAddEditComponent  implements OnInit {

  public formGroup!: UntypedFormGroup;
  allVisitReasons: VisitReason[] = [];
  visitreason: VisitReason | undefined;
  visitorType: VisitorType | undefined;
  addEditPopupHeader: string = "";
  isEditMode: boolean = false;
  languages: Language[] = [];

  originalFormGroup: any = {
    reasonDescription: '',
    reasonTranslations:[]
  }

  constructor(private _localizationService: LocalizationService,
    private _referenceService: ReferenceService,
    private _configurationService: ConfigurationService,
    private _dialogRef: MatDialogRef<ReasonForVisitAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      reason: VisitReason | undefined;
      type: VisitorType | undefined;
      reasonList: VisitReason[];
      languages: Language[];
    },
    private _formBuilder: UntypedFormBuilder
  ) {

    if(this.data.languages){
      this.languages = this.data.languages;
    }
    this.formGroup = this.initFormGroup();
  }

   ngOnInit(): void {
    this.visitreason = this.data.reason;
    this.visitorType = this.data.type;
    this.allVisitReasons = this.data.reasonList;
    this.isAddOrEdit();
    this.populateFormWIthVisitReason();
  }

  private initFormGroup(): UntypedFormGroup {
    const formGroup = this._formBuilder.group({
      description: ['', [Validators.required, Validators.maxLength(250)]]
    });

    this.languages.forEach(lang => {
      const langCode = lang.code.replace('-', '_');
      formGroup.addControl(langCode, this._formBuilder.control('', [Validators.maxLength(250)]));
  });

    return formGroup;
  }

  private populateFormWIthVisitReason() {
    if (this.visitreason) {
      this.formGroup.patchValue({
        description: this.visitreason.description,
      });

      if(this.languages.length > 0){
        this.languages.forEach(lang => {
          const langCode = lang.code.replace('-', '_');
          const translatedValue = this.visitreason?.translations?.find(s => s.languageCode == lang.code)?.description ? this.visitreason?.translations?.find(s => s.languageCode)?.description : '';
          this.formGroup.patchValue({ [langCode]: translatedValue });
          
          this.originalFormGroup.reasonTranslations.push({ langCode: langCode, description: translatedValue });
        });
      }

      this.originalFormGroup.reasonDescription = this.visitreason.description;
    }
  }

  isAddOrEdit() {
    if (this.visitreason) {
      this.isEditMode = true;
    }
  }

  addVisitReason(visitReason: VisitReason) {
    this._referenceService.addVisitReason(visitReason).subscribe({
      next: (visitReason) => {
        if (visitReason != null) {
          this.dismissDialog(visitReason);
        }
      },
      error: (error) => {
        if (error.error && error.error.errorCode) {
          alert(this._localizationService.translate(error.error.errorCode));
        }
        else {
          alert(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_adding_visit_reason"));
        }
        console.error(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_adding_visit_reason"), error);
      }
    })
  }

  updateVisitReason(visitReason: VisitReason) {
    this._referenceService.updateVisitReason(visitReason).subscribe({
      next: (visitReason) => {
        if (visitReason != null) {
          this.dismissDialog(visitReason);
        }
      },
      error: (error) => {
        if (error.error && error.error.errorCode) {
          alert(this._localizationService.translate(error.error.errorCode));
        }
        else {
          alert(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_updating_visit_reason"));
        }
        console.error(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_updating_visit_reason"), error);
      }
    })
  }

  isVisitReasonExisted(visitReason: VisitReason): boolean {
    const isExisted = this.allVisitReasons.some((vs) => {
      return vs.visitorTypeId === visitReason.visitorTypeId && vs.description.toLowerCase() === visitReason.description.toLowerCase().trim() && vs.id !== visitReason.id;
    });

    if (isExisted) {
      alert(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_visit_reason_existed"));
    }

    return isExisted;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = form.controls[controlName].hasError('maxlength');
    return validationOutput;
  }

  saveOnClick() {
    if (this.formGroup.valid && this.visitorType && this.visitorType.id && this.visitorType.id !== '') {
      if (this.isEditMode && this.visitreason) {

            this.languages.forEach(lang => {
              const langCode = lang.code.replace('-', '_');
    
              let reasonranslation = this.visitreason?.translations?.find(t => t.languageCode === lang.code);
              if(reasonranslation){
                reasonranslation.description = this.formGroup.get(langCode)?.value;
              }else{
                const newTransValue = this.formGroup.get(langCode)?.value;
                if(newTransValue){
                  let newTranslation : Translation = { languageCode: lang.code, description: newTransValue };
                  this.visitreason?.translations?.push(newTranslation);
                }
              }
            });

        const updatedVisitReason: VisitReason = {
          id: this.visitreason.id,
          visitorTypeId: this.visitorType.id!,
          isActive: this.visitorType.isActive,
          description: this.formGroup.get('description')?.value,
          translations: this.visitreason.translations !== null ? this.visitreason.translations : undefined,
        }
        const isExisted = this.isVisitReasonExisted(updatedVisitReason);
        if (!isExisted) {
          this.updateVisitReason(updatedVisitReason);
        }
      } else {

        let newTranslations: Translation[] = [];
        this.languages.forEach(lang => {
          const langCode = lang.code.replace('-', '_');
          const newTransValue = this.formGroup.get(langCode)?.value;
          if(newTransValue){
            let newTranslation : Translation = { languageCode: lang.code, description: newTransValue };
            newTranslations.push(newTranslation);
          }
        });

        const newVisitReason: VisitReason = {
          id: undefined,
          visitorTypeId: this.visitorType.id!,
          isActive: true,
          description: this.formGroup.get('description')?.value,
          translations: newTranslations
        };
        const isExisted = this.isVisitReasonExisted(newVisitReason);
        if (!isExisted) {
          this.addVisitReason(newVisitReason);
        }
      }
    }
  }

  disableSaveOnClick(): boolean {
    const currentDescription = this.formGroup.get('description')?.value;
    let isTranslationsDescriptionChanged = false;

    if(this.languages.length > 0){
      this.languages.forEach(lang => {
        const langCode = lang.code.replace('-', '_');
        const currentTranslation = this.formGroup.get(langCode)?.value;
        const originalTranslation = this.originalFormGroup.reasonTranslations.find((t: any) => t.langCode === langCode)?.description;
        if(currentTranslation !== originalTranslation){
          isTranslationsDescriptionChanged = true;
        }
      });
    }

    if (currentDescription === this.originalFormGroup.reasonDescription && !isTranslationsDescriptionChanged) {
      return true;
    }

    return this.formGroup.invalid;
  }

  dismissDialog(result?: VisitReason) {
    this._dialogRef.close(result);
  }

}
