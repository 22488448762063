<div class="main-content">
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">{{ ('siteinductioncompleted_lbl_welcome' |
                translate).replace("{name}", fullname) }}</span>
            <span class="font-size-body-text color-dark-blue">
                {{ ('siteinductioncompleted_lbl_signed_in_first' | translate) }}
                <b class="display-bold bg-primary-blue">{{selectedSite?.name}}</b>.
            </span>
        </div>
    </div>

    <div class="divider"></div>
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">
                {{ ('siteinductioncompleted_lbl_visit_dashboard' | translate) }}
            </span>
        </div>
    </div>

    <div class="divider"></div>
    <mat-divider></mat-divider>
    <div class="divider"></div>
    <div *ngIf="distinctModules != null && distinctModules.length > 0 && !outOfRange">
        <div fxLayout="row">
            <div fxLayout="column">
                <span class="font-size-body-text color-dark-blue" *ngIf="showProductLabel">
                    {{ ('siteinductioncompleted_lbl_view_job' | translate) }}
                </span>
                <span class="font-size-body-text color-dark-blue" *ngIf="!showProductLabel">
                    {{ ('siteinductioncompleted_lbl_reason_for_visit' | translate) }}
                </span>
            </div>
        </div>

        <div>
            <div *ngIf="!isJobReason()">
                <div class="divider"></div>
                <div fxLayout="row" *ngFor="let reason of siteVisitReasons" fxLayoutAlign="center">
                    <div fxLayout="column" fxFlex="95">
                        <li class="color-city-blue" *ngIf="reason.calloutReferenceNumber != ''"> {{
                            reason.calloutReferenceNumber }}
                        </li>
                    </div>
                </div>
            </div>
            <div *ngIf="isJobReason()">
                <div *ngIf="allocatedJobs.length > 0">
                    <div class="divider"></div>
                    <div class="allocatedJobs" *ngFor="let job of allocatedJobs, let last = last">
                        <div class="divider"></div>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="jobInfo">
                                <span class="color-city-blue">#{{job.job.referenceNumber}}</span><br>
                                <span>{{job.job.faultServiceName}}</span><br>
                                <div class="color-city-blue" fxLayout="row" *ngIf="job.job.eta != null">
                                    <div class="job-icon" fxLayoutAlign="start end">
                                        <mat-icon class="color-city-blue">today</mat-icon>
                                    </div>
                                    <div class="jobType" fxLayoutAlign="start end">
                                        <span *ngIf="isReactiveJob(job.job)">ETA: {{job.job.eta | date:'dd-MM-yyyy hh:mm a'}}</span>
                                        <span *ngIf="isPPMJob(job.job)">Due On: {{job.job.eta | date:'dd-MM-yyyy' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="job.jobStatus == 'Not Updated' && !outOfRange && job.fastLinkEnabled">
                                <button mat-raised-button class="update-job-button" fxLayoutAlign="end end"
                                (click)="fastlinkOnClick(job.resourceId, job.job.referenceNumber, job.job.allocatedReferenceId, job.job.calloutReferenceNumber, job.job.jobType)">
                                {{ 'sitesignout_lbl_update_job' | translate }}</button>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <mat-divider *ngIf="!last"></mat-divider>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <div fxLayout="column" fxFlex="100">
                    <div *ngFor="let module of distinctModules; index as i">
                        <div class="divider"></div>
                        <div fxLayout="row" *ngIf="showModule(module)">
                            <button mat-stroked-button name="btn_module_{{module.productName}}"
                                id="btn_module_{{module.productName}}" (click)="moduleOnClick(module)">
                                <img class="site-image" *ngIf="module.productImgUrl" src="{{module.productImgUrl}}" />
                                {{ module.displayName }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="divider"></div>
    <mat-divider></mat-divider>
    <div class="divider"></div>

    <div *ngIf="permitStatus != permitStatusEnum.NA">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <div class="divider"></div>
                {{ ('siteinductioncompleted_lbl_manage_permit' | translate) }}

            </div>
        </div>

        <div fxLayout="row" *ngIf="permitStatus == permitStatusEnum.NEW">
            <div fxLayout="column" fxFlex="100%">
                <div class="divider"></div>
                <button mat-stroked-button (click)="openPermitOnClick()" id="btn_open_permit">
                    {{ ('siteinductioncompleted_lbl_open_permit' | translate) }}
                </button>
            </div>
        </div>

        <div fxLayout="row" *ngIf="permitStatus == permitStatusEnum.DRAFT">
            <div fxLayout="column" fxFlex="100%">
                <div class="divider"></div>
                <button mat-stroked-button (click)="openPermitOnClick()" id="btn_resume_permit">
                    {{ ('siteinductioncompleted_lbl_resume_open_permit' | translate) }}
                </button>
            </div>
        </div>

        <div fxLayout="row" *ngIf="permitStatus == permitStatusEnum.SUMBITTED">
            <div fxLayout="column" fxFlex="100%">
                <div class="divider"></div>
                <button mat-stroked-button (click)="permitWelfareOnClick()" id="btn_permit_welfare_check">
                    {{ ('siteinductioncompleted_lbl_welfare_check' | translate) }}
                </button>
            </div>
        </div>

        <div fxLayout="row"
            *ngIf="permitStatus == permitStatusEnum.SUMBITTED || permitStatus == permitStatusEnum.CLOSED">
            <div fxLayout="column" fxFlex="100%">
                <div class="divider"></div>
                <button mat-stroked-button (click)="closePermitOnClick()" id="btn_close_permit"
                    [disabled]="permitStatus == permitStatusEnum.CLOSED">
                    <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                        {{ ('siteinductioncompleted_lbl_close_permit' | translate) }}
                        <mat-icon class="color-city-green" *ngIf="permitStatus == permitStatusEnum.CLOSED">
                            task_alt
                        </mat-icon>
                    </div>
                </button>
            </div>
        </div>
        <div class="divider"></div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-body-text color-dark-blue">
                {{ ('siteinductioncompleted_lbl_signout_of_site' | translate) }}
            </span>
        </div>
    </div>

    <div class="divider"></div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="100%">
            <button mat-raised-button (click)="siteSignOutOnClick()" id="btn_sitesignout"
                [disabled]="!(permitStatus == permitStatusEnum.NEW || permitStatus == permitStatusEnum.CLOSED || permitStatus == permitStatusEnum.NA)">
                {{ ('siteinductioncompleted_lbl_signing_out' | translate).replace("{site_name}", selectedSite?.name) }}
            </button>
        </div>
    </div>
</div>