import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { LocalizationService } from '../internationalization/localization.service';
import { environment } from 'src/environments/environment';
import { SiteService } from '@app/services/site/site.service';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { BrowserService } from '@app/services/browser/browser.service';
import { ReferenceService } from '@app/services/reference/reference.service';
import { Configuration } from '@app/models/configuration.model';
import { ConfigurationKey } from '@app/shared/enums';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
    selector: 'app-register3',
    templateUrl: './register3.component.html',
    styleUrls: ['./register3.component.scss'],
    standalone: false
})
export class Register3Component implements OnInit {
  configurations : Configuration[] = [];
  currentUser: User | undefined;
  
  constructor(
    private _location: Location,
    private _router: Router,
    private _authService:AuthService,
    private _userService: UserService,
    private _navigationService:NavigationService,
    private _localizationService:LocalizationService,
    private _siteService: SiteService,
    private _closedownService : ClosedownService,
    private _configurationService: ConfigurationService,
  ) {
  }
  
  async ngOnInit() {
    this.currentUser = await this._authService.getUser();
    const rapidGlobalModuleEnable = await this._configurationService.getProfileRapidGlobalRequired();
    const profileInductionModuleEnable = await this._configurationService.getProfileInductionRequired();

    if(rapidGlobalModuleEnable || profileInductionModuleEnable){
      this._navigationService.onChange(false, true);
    }else{
      this._navigationService.onChange(false, false);
    }
  }

  continueRegister() {
    if (this.currentUser){
     let updateInJson = `{"registerStatus":3}`;
     this._userService.updateRegisterStatus(this.currentUser.id, updateInJson).subscribe({
       next: async (result) =>{
         if (result){
          var processFlow = await this._closedownService.getProcessFlow();
          var jobId = processFlow.jobId;
          var siteCode = processFlow.siteCode;
          var token = processFlow.token;
          var linkType = processFlow.linkType;
          if (linkType == 1 &&
            siteCode != '' &&
            jobId != '' &&
            token != '') {
            this._router.navigate(['closedown', linkType, token]);
          }
          else {
            if (await this._siteService.getQrSiteId()) {
              location.replace('qrsignin');
            }
            else {
              location.replace('signin/true');
            }
          }                             
       
         }
       },
       error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate('register_error_update_status'));
          }
       }
     })
    }
  }


  back(){
    this._location.back();
  }

}