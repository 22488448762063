import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { environment } from '@env/environment';

@Pipe({
  name: 'qnaTranslate',
  pure: false,
  standalone: false
})
export class QnaTranslatePipe implements PipeTransform {

  constructor(private _localizationService: LocalizationService) {
  }

  transform(objData: any, oriValue: any = undefined): any {
    if (!Array.isArray(objData) || objData.length === 0) {
      return objData;
    }
  
    const hasValidStructure = objData[0]?.LanguageCode && objData[0]?.Text;
    if (!hasValidStructure) {
      return objData;
    }
  
    const lang = this._localizationService.getNoAsync();
    if (!lang) {
      return objData;
    }

    return (
      objData.find((x: any) => x.LanguageCode === lang)?.Text ||
      objData.find((x: any) => x.LanguageCode === environment.defaultLang)?.Text ||
      oriValue ||
      objData
    );
  }

}
