import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteInductionInfo, User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
    selector: 'app-site-induction-delete',
    templateUrl: './site-induction-delete.component.html',
    styleUrls: ['./site-induction-delete.component.scss'],
    standalone: false
})
export class SiteInductionDeleteComponent implements OnInit {
  siteInductionInfo : SiteInductionInfo | undefined;
  currentUser : User | undefined;

  constructor(private _dialogRef: MatDialogRef<SiteInductionDeleteComponent>,
    private _userService : UserService,
    private _localizationService : LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: User | undefined, induction: SiteInductionInfo | undefined }
    ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.siteInductionInfo = this.data.induction;
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  deleteInduction(){
    if (this.siteInductionInfo && this.siteInductionInfo.id){
      if (this.currentUser){
        this._userService.deleteInduction(this.siteInductionInfo.id).subscribe({
          next: (_ => {
            const indx = this.currentUser?.siteInductionInfos
              .findIndex(v => v.inductionId === this.siteInductionInfo?.inductionId) ?? -1;
            this.currentUser?.siteInductionInfos.splice(indx, indx >= 0 ? 1 : 0);
            this._dialogRef.close(this.currentUser);
          }),
          error: (error => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate('site_induction_delete_error'));
            }
          }) 
        });
      }
    }
  }
}