import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-permit-currenttime',
    templateUrl: './permit-currenttime.component.html',
    styleUrls: ['./permit-currenttime.component.scss'],
    standalone: false
})
export class PermitCurrenttimeComponent implements OnInit {

  @Input('question') question: any;

  constructor(private _datePipe: DatePipe) { }

  ngOnInit(): void {
    if(!this.question.Answer){
      this.question.Answer = this._datePipe.transform(new Date(), 'HH:mm');
    }
  }

  captionStyles(): object {
    return JSON.parse(this.question.CaptionStyles);
  }
}

